import * as utils from '@/shared/utils';
import * as C from 'balaan_constants';

export default {
  data() {
    return {
      partnerGradeMap: {
        none: {char: '', variant: 'secondary'},
        starter1: {char: 'S1', variant: 'secondary'},
        starter2: {char: 'S2', variant: 'secondary'},
        normal: {char: 'N', variant: 'secondary'},
        smart: {char: 'S', variant: 'success'},
        premium: {char: 'P', variant: 'primary'},
      }
    };
  },
  methods: {
    makeShopBadge(shop) {
      const gradeObj = this.partnerGradeMap[shop.partnerGrade] || {char: 'N', variant: 'light'};
      const gradeBadge = this.$R('ADMIN') ?
        `<span class="badge alert-${gradeObj.variant}" title="파트너 등급: ${shop.partnerGrade || '없음'}">${gradeObj.char}</span>` :
        '';
      return gradeBadge +
        `<a href="/#/shop/${shop.shop_id}" target="_blank" class="badge badge-${shop.use_yn === 'n' ? 'secondary' : 'success'}"` +
        ` title="${shop.use_yn === 'n' ? '미사용 SHOP' : ''}">${shop.shop_id}. ${shop.boutique} <i class="fa fa-external-link"></i></a>`;
    },
    makeBrandBadge(brand_no, name, {type = '', showNo = true, showType = true} = {}) {
      const brandType = C.BRAND_TYPE_MAP[type || 'etc'];
      const typeBadge = showType && brandType ?
        `<span class="badge" style="color: ${brandType.color}; background-color: ${brandType.bgColor}">${brandType.char}</span>` :
        ''
      ;
      return `${typeBadge}<span class="badge badge-warning">${showNo && brand_no ? brand_no + '. ' : ''}${name}</span>`;
    },
    makeCategoryBadge(category, title = '') {
      return `<span class="badge badge-light" title="${title}">${category || ''}</span>`;
    },
    makeGoodsNoLinkBadge(goods_no, {copyIcon = false, goodsType = 'new'} = {}) {
      return (goodsType === 'used' ? `<span class="badge" style="background-color: #f0f3f5;">U</span>` : '') +
        `<a href="/#/goods/${goods_no}" target="_blank" class="badge badge-primary">${goods_no} <i class="fa fa-external-link"></i></a>` +
        (copyIcon ? `<span class="badge badge-light" onclick="navigator.clipboard.writeText('${goods_no}')"><i class="fa fa-copy pointer"></i></span>` : '')
        ;
    },
    makeMallLinkBadge(goods_no, MALL_URL, {copyIcon = false, goodsType = 'new'} = {}) {
      return (goodsType === 'used' ? `<span class="badge badge-light" style="background-color: #f0f3f5;">U</span>` : '') +
        `<a href="${MALL_URL}/shop/goods/goods_view.php?goodsno=${goods_no}" class="badge badge-info" target="_blank">` +
        `${goods_no} <i class="fa fa-external-link"></i></a>` +
        (copyIcon ? `<span class="badge badge-light" onclick="navigator.clipboard.writeText('${goods_no}')"><i class="fa fa-copy pointer"></i></span>` : '')
        ;
    },
    makeOptionsBadge(options, {lines = 2} = {}) {
      const optionsHtml = `${options.filter(opt => !opt.not_found).map(opt => `<span class="badge badge-light">${opt.Size}</span>` +
        `<span class="badge badge-${opt.stock > 0 ? 'success' : 'secondary'}" title="판매 가능수량">${opt.stock}</span>` +
        (opt.shop_stock != null && opt.shop_stock !== opt.stock ?
          `<span class="badge badge-${opt.shop_stock > 0 ? 'primary' : 'secondary'}" title="SHOP 의 원본 수량">${opt.shop_stock}</span>` :
          '') +
        (opt.order_stock ? `<span class="badge badge-info" title="결제된 주문수량">${opt.order_stock}</span>` : '') +
        (opt.prepared_stock ? `<span class="badge badge-warning" title="결제 전 주문수량">${opt.prepared_stock}</span>` : '')).join(', ')}`
      ;

      return `<div style="display: -webkit-box; -webkit-line-clamp: ${lines}; -webkit-box-orient: vertical; overflow: hidden;">` +
        `<small><b>${options && options.length ? options[0].optnm : ''}</b></small> ` + optionsHtml + '</div>'
    },
    makeGoodsStatusBadges(row) {
      return [
        row.goods_status,
        row.stock_status,
        [row.display_status, {title: row.display_status_reason ? `[${row._notview_dt}] ${row.display_status_reason} (${row._notview_by})` : ''}]
      ].map(e => utils.badge(...[e].flat())).join('<br/>');
    }
  }
}
