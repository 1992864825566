<template>
  <div>
    <div class="d-flex">
      <div class="mr-2 bg-white p-3 border-secondary b-a-1 pointer select_box" @click="()=> {this.form.status = 'ALL'; this.list()}">
        <div class="text-muted">요청 건수</div>
        <div class="font-2xl bold">{{statusStat.all || 0}}</div>
      </div>
      <div class="mr-2 bg-white p-3 border-secondary b-a-1 pointer select_box" @click="()=> {this.form.status = 'complete'; this.list()}">
        <div class="text-muted">완료 건수</div>
        <div class="font-2xl bold">{{statusStat.complete || 0}}</div>
      </div>
      <div class="mr-2 bg-white p-3 border-secondary b-a-1 pointer select_box" @click="()=> {this.form.status = 'request'; this.list()}">
        <div class="text-muted">미처리 건수</div>
        <div class="font-2xl bold">{{statusStat.request || 0}}</div>
      </div>
      <div class="mr-2 bg-white p-3 border-secondary b-a-1 pointer select_box" @click="()=> {this.form.status = 'reject'; this.list()}">
        <div class="text-muted">반려 건수</div>
        <div class="font-2xl bold">{{statusStat.reject || 0}}</div>
      </div>
    </div>

    <div class="text-right" style="margin-top: -34px">
      <b-dropdown right variant="light" class="m-1">
        <template #button-content>
          <i class="fa fa-copy"></i>
        </template>
        <b-dropdown-item @click="copy('goods_no')">발란코드</b-dropdown-item>
        <b-dropdown-item @click="copy('catalog')">카탈로그</b-dropdown-item>
        <b-dropdown-item @click="copy('matched_sku_id')">통합SKU</b-dropdown-item>
      </b-dropdown>
      <b-button variant="primary" class="m-1" @click="changeStatus('complete')">완료</b-button>
      <b-button class="m-1" @click="changeStatus('request')">완료취소</b-button>
      <b-button variant="danger" class="m-1" @click="changeStatus('reject')">반려</b-button>
    </div>

    <c-table v-if="itemMode === 'list'" :table-data="items.list" :fields="fields" :perPage.sync="perPage"
             :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list"
             :caption="items.list.length + ' 개 상품'" @get-more="list(true)"></c-table>
  </div>
</template>

<script>
import xlsx from '@/views/modules/Xlsx.vue'
import {
  MALL_GOODS_LINK,
  ORDER_STATUS_CODE,
  CONFIRMED_COLUMNS,
  GODO_COL_TARGET,
  CF_TO_BL_GOODS_ADDON
} from 'balaan_constants'
import * as momentBiz from 'moment-business-days';
import ListDataMixin from '../modules/ListDataMixin'

const statusMap = {
  request: '요청',
  complete: '완료',
  reject: '반려'
}

export default {
  name: 'MasterRequestList',
  title: '카탈로그 수정요청',
  mixins: [
    ListDataMixin
  ],
  components: {xlsx},
  data() {
    return {
      MALL_GOODS_LINK,
      ORDER_STATUS_CODE,
      CONFIRMED_COLUMNS,
      GODO_COL_TARGET,
      CF_TO_BL_GOODS_ADDON,
      momentBiz,
      statusMap,
      statusStat: {all: 0},
      shop: [],
      shopMap: {},
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      form: {
        status: 'ALL',
        limit: 100,
        skip: 0,
      },
      lastBody: {list: {}},
      items: {list: []},
      item: {},
      busy: {list: false, listmore: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      fields: [
        {key: 'selected'},
        {key: '_img60', label: '이미지', class: 'text-center'},
        {key: 'html2', label: `상품정보`, style: {minWidth: '100px'}},
        {
          key: 'html4',
          label: `<span class="badge badge-light">통합 SKU</span><br><span class="badge badge-light">카탈로그</span>`,
          class: 'text-center'
        },
        // {key: 'html5', label: '판매가격', class: 'text-center'},
        {key: 'html6', label: '사유', class: 'text-center', style: {maxWidth: '350px'}},
        {key: 'html3', label: '상태', class: 'text-center'},
        {key: 'request_date', label: '요청일', class: 'text-center'},
        {key: 'complete_date', label: '완료 / 반려일', class: 'text-center'},
        {key: 'worker', label: '작업자', class: 'text-center'},
      ],
      perPage: 20,
      itemMode: 'list',
      picGroup: 'ALL',
      picWidth: 175,
      picInfoTop: true,
      picInfoBottom: true,
      xlsx: {
        keys: [],
        labels: [],
      },
      port: +(localStorage.getItem('port') || 3030),
    }
  },
  async created() {
    this.busy.list = true;

    let meta = await this.$api.getMeta('shop,brand,category');

    meta.shop.forEach(s => {
      s.value = s.boutique;
      s.label = `${s.use_yn !== 'y' ? '[미사용] ' : ''}${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    }); // use_yn 무관 일단 정보는 필요
    this.shop = meta.shop.filter(e => e.use_yn === 'y').sort((a, b) => a.shop_id - b.shop_id);


    this.brand = meta.brand.map(e => {
      return this.brandMap[e.brand_no] = {...e, value: e.brand_no, label: `${e.brand_nm} (${e.brand_nm_kr})`};
    }).sort((a, b) => a.label.localeCompare(b.label));

    this.category = meta.category.map(e => {
      return this.categoryMap[e.category] = {...e, value: e.category, label: `${e.category} (${e.category_nm})`};
    }).sort((a, b) => (a.value.length - b.value.length) * 10 + a.value.localeCompare(b.value));

    this.list();
  },
  async beforeDestroy() {
    if (this.abortController) this.abortController.abort();
  },
  watch: {
    picWidth() {
      this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    },
    perPage() {
      this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    }
  },
  methods: {
    async changeStatus(status) {
      const selected = this.items.list.filter(e => e.selected);
      if (!selected.length) {
        return alert('상태를 변경할 요청을 선택해주세요')
      }
      const body = {status, ids: selected.map(s => s._id)};
      let j = await this.$api.postJson('/master/setStatus', body);
      this.list();
      if (j) {
        alert('상태 변경을 완료했습니다');
      } else {
        alert('요청을 처리하던 중 오류가 발생했습니다.');
      }
    },
    async list(more) {
      let body = {};
      if (this.form.status !== 'ALL') {
        body.status = this.form.status;
      }
      body.limit = this.form.limit;
      body.skip = this.form.skip;
      let j = await this.$api.postTable(this, '/master/matchingList', body, {more, fnAssign: this.assignTableData});
      if (j) {
        this.statusStat = {all: 0};
        j.matchingStat.forEach(stat => {
          this.statusStat.all += stat.cnt;
          this.statusStat[stat.status] = stat.cnt;
        });
        this.$forceUpdate();
      }
    },
    assignTableData(e) {
      if (e.category) {
        let cates = [];
        for (let i = 3; i <= e.category.length; i += 3) {
          if (e.category.substring(i - 3, i) === '001') cates.push('전체');
          else cates.push(this.categoryMap[e.category.substring(0, i)] && this.categoryMap[e.category.substring(0, i)].category_nm || '?');
        }
        e.cate = cates.join(' > ');
      } else if (e.brand_no !== 0) {
        // 개인결제창이 아닌 경우
        console.log('카테고리 없음 : ', e);
      }

      e.catalog = e.gm_id ? e.gm_id : e.matched_sku_id || '';
      e.img = e.images && e.images[0] ? `https://i.balaan.io/${e.image_path}${e.images[0].thumb_200}` : (e.img_urls ? e.img_urls[0] : '');
      if (!e.shop) {
        e.html2 = '삭제된 상품입니다.';
      } else {
        const shop = this.shopMap[e.shop_id];
        e.html2 = this.makeShopBadge(shop) + ' ' + this.makeBrandBadge(e.brand_no, e.brand_nm, {type: this.brandMap[e.brand_no].brand_type}) + '<br/>' +
          this.makeCategoryBadge(e.cate, e.category) + ' ' + this.makeGoodsNoLinkBadge(e.goods_no) + '<br/>' + e.goods_nm
        ;
      }
      e.html6 = e.reason;
      e.html3 = this.statusMap[e.status];
      e.html4 = `<span class="badge badge-success">${e.matched_sku_id || ''}</span><br><span class="badge badge-light">${e.catalog}</span>`
      e.html5 = `${this.$utils.comma(e.price || 0)}`;
    },
    picClick(e) {
      e.selected = !e.selected;
      this.$forceUpdate();
    },
    copy(col, {withQuotes = false} = {}) {
      let selected = this.items.list.filter(e => e.selected);
      if (!selected.length) return alert('복사할 상품을 선택해주세요');
      let res = this.$utils.copyToClipboard(selected.map(e => withQuotes ? `'${e[col].toString().replace(/'/g, "\\'")}'` : e[col]).join(withQuotes ? ',\n' : '\n'));
      if (res) this.$alertTop(`복사되었습니다`);
    },
  }
}
</script>

<style>
.select_box {
  border-radius: 5px;
  width: 135px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3);
}
</style>
