<template>
  <span>
    <b-button v-if="types.includes('xlsx')" @click="down('xlsx')" class="ml-1" :size="size" variant="success">XLSX</b-button>
    <b-button v-if="types.includes('csv')" @click="down('csv')" class="ml-1" :size="size" variant="success">CSV</b-button>
    <b-button v-if="types.includes('tsv')" @click="down('tsv')" class="ml-1" :size="size" variant="secondary">TSV</b-button>
    <b-button v-if="types.includes('copy')" @click="copy('tsv')" class="ml-1" :size="size" variant="warning">Copy</b-button>
    <iframe name="xlsx_frame" style="display:none"></iframe>
    <form :action="getHost() + '/data/down'" ref="xlsx_form" method="POST" target="xlsx_frame" style="display:none">
      <input ref="json_data" type="hidden" name="j" />
    </form>
  </span>
</template>

<script>
  import {down} from '@/shared/impexp'
  import {getHost} from '@/shared/api'

  export default {
    name: "Xlsx",
    props: {types: Array, name: String, postName: String, preFunc: Function, xlsx_id: String, data: Array, labels: Array, keys: Array, size: String},
    data() {
      return {
        getHost,
      };
    },
    async created() {
    },
    methods: {
      getPostName(postName) {
        if (postName === '') return '';
        postName = postName || 'dt';
        switch (postName) {
          case 'm':
            return '_' + this.$utils.kstM().replace(' ', '_');
          case 'd':
            return '_' + this.$utils.kstD().replace(' ', '_');
          case 'dhm':
            return '_' + this.$utils.kstDHM().replace(' ', '_');
          case 'dt':
            return '_' + this.$utils.kstDT().replace(' ', '_');
          default:
            return '_' + postName;
        }
      },
      async down(type) {
        // 다양한 데이터를 지정한 타입으로 다운받는다.
        if (this.preFunc && !await this.preFunc()) return;

        let id = this.xlsx_id;
        let name = `${this.name || 'Download'}${this.getPostName(this.postName)}.${type}`;

        // id 가 있다면 해당 id로 의뢰한다.
        if (id) {
          this.$refs.json_data.value = JSON.stringify({id, name});
          this.$refs.xlsx_form.submit();
          return;
        }

        let keys = this.keys || [];
        let labels = this.labels || [];
        let data = this.data || [];

        // 1000 줄 이하는 바로, 그 이상은 의뢰한다 -> keys 가 있다면 5만 cell 이상, 없다면 5000 줄 기준으로 변경
        if (keys.length && data.length * keys.length > 50000 || data.length > 5000) {
          this.$refs.json_data.value = JSON.stringify({id, name, data, labels, keys, type});
          this.$refs.xlsx_form.submit();
          return;
        }
        down(data, labels, keys, name, type);
      },
      copy(type) {
        let tsv = '';
        if (type === 'brands') {
          tsv = this.unmatchedListItem.brands.map(e=>`${e.brand}\t${e.cnt}`).join('\n');
        } else if (type === 'cates') {
          tsv = this.unmatchedListItem.cates.map(e=>`${e.category}\t${e.origin_category}\t${e.cnt}`).join('\n');
        }
        this.$utils.copyToClipboard(tsv);
        this.$alertTop(`복사되었습니다`);
      },
    },
  }
</script>
